
import { defineComponent } from 'vue';
import { fileService } from '@/services/fileUpload';
import { UploadFile } from '@/models';
import prettyBytes from 'pretty-bytes';

export default defineComponent({
  name: 'FileUpload',
  data () {
    return {
      uploadProgress: 100,
      files: [] as UploadFile[]
    };
  },
  methods: {
    async uploadFile (event: Event) {
      const input = event.target as HTMLInputElement;
      if (!input.files || input.files.length === 0) {
        return;
      }

      await fileService.upload(input.files[0], (event: ProgressEvent) => {
        this.uploadProgress = parseFloat((event.loaded / event.total * 100).toFixed(2));
      });
      this.uploadProgress = 100;
      input.value = '';

      this.files = await fileService.uploadedFiles();
    },
    url (filename: string): string {
      return `${location.protocol}//${location.host}/releases/${filename}`;
    },
    prettyBytes (nrBytes: number) {
      return prettyBytes(nrBytes);
    },
    async deleteFile (filename: string) {
      const ok = await fileService.delete(filename);
      if (ok) {
        this.files = await fileService.uploadedFiles();
      }
    },
    async publishFile (filename: string) {
      const ok = await fileService.publish(filename);
      if (ok) {
        this.files = await fileService.uploadedFiles();
      }
    },
    async unpublishFile (filename: string) {
      const ok = await fileService.unpublish(filename);
      if (ok) {
        this.files = await fileService.uploadedFiles();
      }
    }
  },
  async mounted () {
    this.files = await fileService.uploadedFiles();
  }
});
