<template>
  <div class="login">
    <div>
      <h2>Login Required</h2>
      <p v-if="!loginOk" class="warning">Use or password invalid.</p>
      <div class="grid" ref="container">
        <p>User</p>
        <input v-model="user" placeholder="User">
        <p>Password</p>
        <input v-model="password" type="password">
        <button class="action" v-on:click="login">Submit</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { authService } from '@/services/authentification';
import anime from 'animejs';

export default defineComponent({
  name: 'Login',
  data () {
    return {
      user: '',
      password: '',
      loginOk: true
    };
  },
  methods: {
    async login () {
      const ok = await authService.login(this.user, this.password);
      if (ok) {
        return;
      }

      const xMax = 7;
      anime({
        targets: this.$refs.container as HTMLElement,
        easing: 'easeInOutSine',
        duration: 250,
        translateX: [
          { value: xMax * -1 },
          { value: xMax },
          { value: xMax / -2 },
          { value: xMax / 2 },
          { value: 0 }
        ]
      });
      this.loginOk = false;
    },
    keyDown (e: KeyboardEvent) {
      if (e.keyCode === 13) {
        this.login();
      }
    }
  },
  mounted () {
    window.addEventListener('keydown', this.keyDown);
  },
  beforeUnmount () {
    window.removeEventListener('keydown', this.keyDown);
  }
});
</script>

<style scoped lang="scss">
.login {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

.warning {
  color: red;
  padding: 10px 0;
}

.grid {
  margin-top: 10px;
  display: grid;
  grid-row-gap: 10px;
}
</style>
