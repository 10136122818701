<template>
  <div class="home">
    <h1>QLign Telemetry</h1>
    <div class="dashboard">
      <facts v-bind:qligns="qligns"></facts>
      <software-versions></software-versions>
      <client-distribution></client-distribution>
      <file-upload class="file-upload"></file-upload>
    </div>
    <div class="history">
      <data-age v-bind:qligns="qligns"></data-age>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { qlignService } from '@/services/qlign';
import { QLignModel } from '@/models';
import Facts from '@/components/graphs/Facts.vue';
import SoftwareVersions from '@/components/graphs/SoftwareVersions.vue';
import DataAge from '@/components/graphs/DataAge.vue';
import ClientDistribution from '@/components/graphs/ClientDistribution.vue';
import FileUpload from '@/components/management/FileUpload.vue';

export default defineComponent({
  name: 'Home',
  components: {
    FileUpload,
    ClientDistribution,
    DataAge,
    SoftwareVersions,
    Facts
  },
  data () {
    return {
      qligns: [] as QLignModel[]
    };
  },
  async mounted () {
    this.qligns = await qlignService.all();
  }
});
</script>

<style scoped lang="scss">
@import "src/scss/breakpoints";

.dashboard {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr repeat(2, 1fr);
  margin-bottom: 2em;

  .file-upload {
    grid-column: span 3;
  }
}
</style>
