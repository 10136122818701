
import { defineComponent } from 'vue';
import { QLignModel } from '@/models';
import { qlignService } from '@/services/qlign';

export default defineComponent({
  name: 'Facts',
  props: {
    qligns: {
      required: true,
      type: Object as () => QLignModel[]
    }
  },
  data () {
    return {
      latestVersion: ''
    };
  },
  computed: {
    nrAlignerVersions (): number {
      const alignerVersions = new Set();
      this.$props.qligns.forEach(item => alignerVersions.add(item.alignerVersion));
      return alignerVersions.size;
    },
    nrOsVersions (): number {
      const osVersions = new Set();
      this.$props.qligns.forEach(item => osVersions.add(item.osVersion));
      return osVersions.size;
    },
    percentageLatestVersion (): number {
      return Math.floor(this.$props.qligns.filter(item => item.alignerVersion === this.latestVersion).length / this.$props.qligns.length * 100);
    }
  },
  async mounted () {
    this.latestVersion = await qlignService.latestAlignerVersion();
  }
});
