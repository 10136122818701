
import { defineComponent } from 'vue';
import { OsVersion, UserDeviceDistribution } from '@/models';
import { qlignService } from '@/services/qlign';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const COLORS = ['#00ffcc', '#ff6666', '#66ff66', '#0066ff'];

export default defineComponent({
  name: 'ClientDistribution',
  data () {
    return {
      osVersions: [] as OsVersion[],
      clientDistributions: { hdmi: 0, desktop: 0, android: 0, other: 0 } as UserDeviceDistribution,
      chart: null as (unknown | null)
    };
  },
  async mounted () {
    qlignService.clientDistribution().then((clientDistributions) => {
      this.clientDistributions = clientDistributions;

      const labels = [];
      const values = [];

      for (const [key, value] of Object.entries(this.clientDistributions)) {
        labels.push(key);
        values.push(value);
      }
      const data = {
        labels,
        datasets: [{
          label: 'User Device Distribution',
          data: values,
          backgroundColor: COLORS
        }]
      };
      this.chart = new Chart(this.$refs.canvasDistribution as HTMLCanvasElement, {
        type: 'pie',
        data
      });
    });
  }
});
