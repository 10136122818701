<template>
  <div>
    <h2>Data</h2>
    <div class="facts-table">
      <p>System</p>
      <p>Operating Hours</p>
      <p>Customers</p>
      <p>Reports</p>
      <p>USB-Printer</p>
      <p>DB Target Data</p>
      <p>User Devices</p>
      <p>Timestamp</p>
      <p>Age</p>
      <template v-for="item in qligns">
        <p>
          <span class="id">{{ item.uuid }}</span>
          <br><span class="subtitle">Aligner: {{ item.alignerVersion }}, OS: {{ item.osVersion }}</span>
        </p>
        <p>{{ item.operatingHours }}</p>
        <p>{{ item.nrCustomers }}</p>
        <p>{{ item.nrReports }}</p>
        <p v-if="item.usbPrinterConnected"> yes ({{ item.nrUsbPrintJobs }} prints)</p>
        <p v-else> no ({{ item.nrUsbPrintJobs }} prints)</p>
        <p>{{ item.versionTargetData }}</p>
        <p>
          {{ userDevices(item) }}
        </p>
        <p v-if="item.timestamp">{{ $d(new Date(item.timestamp), 'long') }}</p>
        <p v-else>--</p>
        <p v-if="item.timestamp">{{ age(item.timestamp) }} days</p>
        <p v-else>--</p>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Clients, QLignModel } from '@/models';

function dateDaysDifference (d1: Date, d2: Date) {
  const t2 = d2.getTime();
  const t1 = d1.getTime();
  return Math.abs(Math.ceil((t2 - t1) / (24 * 3600 * 1000)));
}

export default defineComponent({
  name: 'DataAge',
  props: {
    qligns: {
      required: true,
      type: Object as () => QLignModel[]
    }
  },
  methods: {
    userDevices (item: QLignModel): string {
      const usages: { label: string, nr: number }[] = [];
      let total = 0;

      if (item.clientInfo[Clients.HDMI].counter > 0) {
        usages.push({ label: Clients.HDMI, nr: item.clientInfo[Clients.HDMI].counter });
        total += item.clientInfo[Clients.HDMI].counter;
      }
      if (item.clientInfo[Clients.DESKTOP_APP].counter > 0) {
        usages.push({ label: Clients.DESKTOP_APP, nr: item.clientInfo[Clients.DESKTOP_APP].counter });
        total += item.clientInfo[Clients.DESKTOP_APP].counter;
      }
      if (item.clientInfo[Clients.ANDROID_APP].counter > 0) {
        usages.push({ label: Clients.ANDROID_APP, nr: item.clientInfo[Clients.ANDROID_APP].counter });
        total += item.clientInfo[Clients.ANDROID_APP].counter;
      }
      if (item.clientInfo[Clients.OTHER].counter > 0) {
        usages.push({ label: Clients.OTHER, nr: item.clientInfo[Clients.OTHER].counter });
        total += item.clientInfo[Clients.OTHER].counter;
      }

      if (usages.length === 1) {
        return usages[0].label;
      }

      return usages.map(item => `${item.label} ${(item.nr / total * 100).toFixed(0)}%`).join(', ');
    },
    age (timestamp: string): number {
      return dateDaysDifference(new Date(timestamp), new Date());
    },
    formatTimestamp (timestamp: string): string {
      return (new Date(timestamp)).toString();
    }
  }
});
</script>

<style scoped lang="scss">
.facts-table {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(9, auto);
  grid-row-gap: 1em;
  grid-column-gap: 0.5em;

  p:nth-of-type(9n+1) {
    text-align: left;
  }

  .id {
    font-family: monospace;
  }

  .subtitle {
    font-size: 50%;
  }
}
</style>
