import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store, key } from './store';
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
    locale: 'en',
    datetimeFormats: {
        en: {
            short: {
                year: 'numeric', month: 'short', day: 'numeric'
            },
            long: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                weekday: 'short',
                hour: 'numeric',
                minute: 'numeric'
            },
            hour: {
                hour: 'numeric',
                minute: 'numeric'
            }
        }
    }
});

const app = createApp(App);
app.use(store, key);
app.use(router);
app.use(i18n);
app.mount('#app');
