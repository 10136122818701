
import { defineComponent } from 'vue';
import Login from '@/components/Login.vue';
import { authService } from '@/services/authentification';

export default defineComponent({
  name: 'App',
  components: { Login },
  mounted () {
    authService.checkStorage();
  }
});
