<template>
  <div class="software-grid">
    <h2>Aligner Versions</h2>
    <canvas ref="canvasAligner"></canvas>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AlignerVersion, OsVersion } from '@/models';
import { qlignService } from '@/services/qlign';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const COLORS = ['#00ffcc', '#ff6666', '#66ff66', '#ff9900',
  '#92a8d1', '#6b5b95', '#e3eaa7', '#eca1a6',
  '#f18973', '#50394c', '#618685', '#3e4444'];

export default defineComponent({
  name: 'SoftwareVersions',
  data () {
    return {
      osVersions: [] as OsVersion[],
      alignerVersions: [] as AlignerVersion[],
      chart: null as (unknown | null)
    };
  },
  async mounted () {
    qlignService.alignerVersions().then((alignerVersions) => {
      this.alignerVersions = alignerVersions;
      const data = {
        labels: this.alignerVersions.map(item => item.alignerVersion),
        datasets: [{
          label: 'Aligner Versions',
          data: this.alignerVersions.map(item => item.count),
          backgroundColor: COLORS.slice(0, this.alignerVersions.length)
        }]
      };
      this.chart = new Chart(this.$refs.canvasAligner as HTMLCanvasElement, {
        type: 'pie',
        data
      });
    });
  }
});
</script>

<style scoped lang="scss">
canvas {
  max-width: 100%;
}
</style>
