
import { defineComponent } from 'vue';
import { authService } from '@/services/authentification';
import anime from 'animejs';

export default defineComponent({
  name: 'Login',
  data () {
    return {
      user: '',
      password: '',
      loginOk: true
    };
  },
  methods: {
    async login () {
      const ok = await authService.login(this.user, this.password);
      if (ok) {
        return;
      }

      const xMax = 7;
      anime({
        targets: this.$refs.container as HTMLElement,
        easing: 'easeInOutSine',
        duration: 250,
        translateX: [
          { value: xMax * -1 },
          { value: xMax },
          { value: xMax / -2 },
          { value: xMax / 2 },
          { value: 0 }
        ]
      });
      this.loginOk = false;
    },
    keyDown (e: KeyboardEvent) {
      if (e.keyCode === 13) {
        this.login();
      }
    }
  },
  mounted () {
    window.addEventListener('keydown', this.keyDown);
  },
  beforeUnmount () {
    window.removeEventListener('keydown', this.keyDown);
  }
});
