import axios from 'axios';
import { AlignerVersion, OsVersion, QLignModel, UserDeviceDistribution } from '@/models';
import { unauthorizedWrapper } from '@/services/authentification';
import { API_PREFIX } from '@/router/paths';

const urls = {
    ALL: `${API_PREFIX}/qligns/`,
    MODEL: `${API_PREFIX}/qligns/models`,
    ALIGNER_VERSIONS: `${API_PREFIX}/qligns/models/aligner-versions`,
    OS_VERSIONS: `${API_PREFIX}/qligns/models/os-versions`,
    LATEST_VERSION: `${API_PREFIX}/qligns/versions/latest`,
    CLIENT_DISTRIBUTION: `${API_PREFIX}/qligns/models/client-distribution`
};

export const qlignService = {
    async all (): Promise<QLignModel[]> {
        try {
            const response = await unauthorizedWrapper(axios.get.bind(this, urls.MODEL));
            return response.data as QLignModel[];
        } catch (e) {
            // pass
        }
        return [];
    },
    async alignerVersions (): Promise<AlignerVersion[]> {
        try {
            const response = await unauthorizedWrapper(axios.get.bind(this, urls.ALIGNER_VERSIONS));
            return response.data as AlignerVersion[];
        } catch (e) {
            // pass
        }
        return [];
    },
    async osVersions (): Promise<OsVersion[]> {
        try {
            const response = await unauthorizedWrapper(axios.get.bind(this, urls.OS_VERSIONS));
            return response.data as OsVersion[];
        } catch (e) {
            // pass
        }
        return [];
    },
    async latestAlignerVersion (): Promise<string> {
        try {
            const response = await unauthorizedWrapper(axios.get.bind(this, urls.LATEST_VERSION));
            return response.data as string;
        } catch (e) {
            // pass
        }
        return '';
    },
    async clientDistribution (): Promise<UserDeviceDistribution> {
        try {
            const response = await unauthorizedWrapper(axios.get.bind(this, urls.CLIENT_DISTRIBUTION));
            return response.data as UserDeviceDistribution;
        } catch (e) {
            // pass
        }
        return { hdmi: 0, desktop: 0, android: 0, other: 0 };
    }
};
