import axios, { AxiosResponse } from 'axios';
import { store } from '@/store';

const urls = {
    LOGIN: '/api/token'
};

const STORAGE_TOKEN_KEY = 'token';

export const unauthorizedWrapper = async function (callback: () => Promise<unknown>): Promise<AxiosResponse> {
    try {
        return await callback() as AxiosResponse;
    } catch (e) {
        if (e.response.status === 401) {
            store.commit('setUnAuthorized');
        }
        throw e;
    }
};

export const authService = {
    checkStorage (): void {
        const token = localStorage.getItem(STORAGE_TOKEN_KEY);
        if (token) {
            axios.defaults.headers.common = { Authorization: `bearer ${token}` };
            store.commit('setAuthorized', token);
        }
    },
    async login (user: string, password: string): Promise<boolean> {
        const config = {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        };

        const params = new URLSearchParams();
        params.append('username', user);
        params.append('password', password);

        try {
            const response = await axios.post(urls.LOGIN, params, config);
            const token = response.data.access_token;
            axios.defaults.headers.common = { Authorization: `bearer ${token}` };
            store.commit('setAuthorized', token);
            localStorage.setItem(STORAGE_TOKEN_KEY, token);
        } catch (e) {
            return false;
        }

        return true;
    }
};
