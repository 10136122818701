import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';

export interface State {
    count: number,
    authorized: boolean,
    accessToken: string
}

export const key: InjectionKey<Store<State>> = Symbol('description');

export const store = createStore<State>({
    state: {
        count: 0,
        authorized: false,
        accessToken: ''
    },
    mutations: {
        setAuthorized (state, data: string) {
            state.authorized = true;
            state.accessToken = data;
        },
        setUnAuthorized (state) {
            state.authorized = false;
        }
    }
});

// define your own `useStore` composition function
export function useStore (): Store<State> {
    return baseUseStore(key);
}
