import axios from 'axios';
import { unauthorizedWrapper } from '@/services/authentification';
import { UploadFile } from '@/models';
import { API_PREFIX } from '@/router/paths';

const urls = {
    UPLOAD: `${API_PREFIX}/files/upload/`,
    LIST: `${API_PREFIX}/files/list`,
    DELETE: `${API_PREFIX}/files/`,
    PUBLISH: `${API_PREFIX}/files/publish/`,
    UNPUBLISH: `${API_PREFIX}/files/unpublish/`
};

export const fileService = {
    async upload (file: File, callback: (event: ProgressEvent) => void): Promise<boolean> {
        const config = {
            onUploadProgress: callback
        };

        const formData = new FormData();
        formData.append('in_file', file);

        try {
            await unauthorizedWrapper(axios.post.bind(this, urls.UPLOAD, formData, config));
        } catch (e) {
            return false;
        }
        return true;
    },
    async delete (file: string): Promise<boolean> {
        try {
            await unauthorizedWrapper(axios.delete.bind(this, urls.DELETE + file));
            return true;
        } catch (e) {
            return false;
        }
    },
    async publish (file: string) {
        try {
            await unauthorizedWrapper(axios.post.bind(this, urls.PUBLISH + file));
        } catch (e) {
            return false;
        }
        return true;
    },
    async unpublish (file: string) {
        try {
            await unauthorizedWrapper(axios.delete.bind(this, urls.UNPUBLISH + file));
        } catch (e) {
            return false;
        }
        return true;
    },
    async uploadedFiles (): Promise<UploadFile[]> {
        try {
            const response = await unauthorizedWrapper(axios.get.bind(this, urls.LIST));
            return response.data as UploadFile[];
        } catch (e) {
            return [];
        }
    }
};
