<template>
  <div>
    <h2>Facts</h2>
    <div class="facts-table">
      <p>Number of Systems: </p>
      <p>{{ qligns.length }}</p>
      <p>Number of Different OS Versions: </p>
      <p>{{ nrOsVersions }}</p>
      <p>Number of Different Aligner Versions: </p>
      <p>{{ nrAlignerVersions }}</p>
      <p>Latest Aligner Version:</p>
      <p>{{ latestVersion }}</p>
      <p>Percentage Using Latest Version:</p>
      <p>{{ percentageLatestVersion }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { QLignModel } from '@/models';
import { qlignService } from '@/services/qlign';

export default defineComponent({
  name: 'Facts',
  props: {
    qligns: {
      required: true,
      type: Object as () => QLignModel[]
    }
  },
  data () {
    return {
      latestVersion: ''
    };
  },
  computed: {
    nrAlignerVersions (): number {
      const alignerVersions = new Set();
      this.$props.qligns.forEach(item => alignerVersions.add(item.alignerVersion));
      return alignerVersions.size;
    },
    nrOsVersions (): number {
      const osVersions = new Set();
      this.$props.qligns.forEach(item => osVersions.add(item.osVersion));
      return osVersions.size;
    },
    percentageLatestVersion (): number {
      return Math.floor(this.$props.qligns.filter(item => item.alignerVersion === this.latestVersion).length / this.$props.qligns.length * 100);
    }
  },
  async mounted () {
    this.latestVersion = await qlignService.latestAlignerVersion();
  }
});
</script>

<style scoped lang="scss">
.facts-table {
  padding: 10px;
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 1em;

  p:nth-of-type(2n+1) {
    justify-self: self-start;
  }
}
</style>
