<template>
  <div>
    <h2>File Storage</h2>
    <div class="file-list" v-if="files.length > 0">
      <p>Name</p>
      <p>Size</p>
      <p>URL</p>
      <div></div>
      <div></div>
      <template v-for="file in files">
        <p>{{ file.name }}</p>
        <p>{{ prettyBytes(file.nrBytes) }}</p>
        <a v-if="file.published" v-bind:href="url(file.name)">
          <p>{{ url(file.name) }}</p>
        </a>
        <p v-else>private file</p>
        <div class="action delete" v-on:click="deleteFile(file.name)">
          delete
        </div>
        <div v-if="!file.published" class="action publish" v-on:click="publishFile(file.name)">
          publish
        </div>
        <div v-else class="action unpublish" v-on:click="unpublishFile(file.name)">
          unpublish
        </div>
      </template>
    </div>
    <div class="upload-area">
      <div class="upload-button" v-if="uploadProgress === 100">
        <label for="file-upload">Upload File</label>
        <input id="file-upload" type="file" v-on:change="uploadFile">
      </div>
      <p v-else>Upload progress: {{ uploadProgress }}%</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { fileService } from '@/services/fileUpload';
import { UploadFile } from '@/models';
import prettyBytes from 'pretty-bytes';

export default defineComponent({
  name: 'FileUpload',
  data () {
    return {
      uploadProgress: 100,
      files: [] as UploadFile[]
    };
  },
  methods: {
    async uploadFile (event: Event) {
      const input = event.target as HTMLInputElement;
      if (!input.files || input.files.length === 0) {
        return;
      }

      await fileService.upload(input.files[0], (event: ProgressEvent) => {
        this.uploadProgress = parseFloat((event.loaded / event.total * 100).toFixed(2));
      });
      this.uploadProgress = 100;
      input.value = '';

      this.files = await fileService.uploadedFiles();
    },
    url (filename: string): string {
      return `${location.protocol}//${location.host}/releases/${filename}`;
    },
    prettyBytes (nrBytes: number) {
      return prettyBytes(nrBytes);
    },
    async deleteFile (filename: string) {
      const ok = await fileService.delete(filename);
      if (ok) {
        this.files = await fileService.uploadedFiles();
      }
    },
    async publishFile (filename: string) {
      const ok = await fileService.publish(filename);
      if (ok) {
        this.files = await fileService.uploadedFiles();
      }
    },
    async unpublishFile (filename: string) {
      const ok = await fileService.unpublish(filename);
      if (ok) {
        this.files = await fileService.uploadedFiles();
      }
    }
  },
  async mounted () {
    this.files = await fileService.uploadedFiles();
  }
});
</script>

<style scoped lang="scss">
$section-distance: 1em;
$color-button-default: #2c3e50;
$color-button-success: limegreen;
$color-button-danger: crimson;

%-button {
  cursor: pointer;
  color: #fff;
  padding: 4px 10px;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  width: fit-content;

  &:hover {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.75);
  }
}

h2 {
  padding-top: $section-distance;
}

.file-list {
  display: grid;
  grid-template-columns: 1fr auto 1fr auto auto;
  padding-top: $section-distance;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  padding-left: 1em;
  padding-right: 1em;

  p, a {
    align-self: center;
  }
}

.upload-button {
  @extend %-button;
  background-color: $color-button-default;

  input {
    width: 0;
    height: 0;
  }

  label {
    width: 100%;
    height: 100%;
  }
}

.upload-area {
  display: flex;
  padding-top: $section-distance;
  align-items: center;
  justify-content: center;
}

.action {
  @extend %-button;

  &.delete, &.unpublish {
    background-color: $color-button-danger;
  }

  &.publish {
    background-color: $color-button-success;
  }
}
</style>
