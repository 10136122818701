
import { defineComponent } from 'vue';
import { qlignService } from '@/services/qlign';
import { QLignModel } from '@/models';
import Facts from '@/components/graphs/Facts.vue';
import SoftwareVersions from '@/components/graphs/SoftwareVersions.vue';
import DataAge from '@/components/graphs/DataAge.vue';
import ClientDistribution from '@/components/graphs/ClientDistribution.vue';
import FileUpload from '@/components/management/FileUpload.vue';

export default defineComponent({
  name: 'Home',
  components: {
    FileUpload,
    ClientDistribution,
    DataAge,
    SoftwareVersions,
    Facts
  },
  data () {
    return {
      qligns: [] as QLignModel[]
    };
  },
  async mounted () {
    this.qligns = await qlignService.all();
  }
});
