export interface ClientDistribution {
    hdmi: number;
    desktop: number;
    android: number;
    other: number;
}

export enum Clients {
    HDMI = 'HDMI',
    DESKTOP_APP = 'DESKTOP_APP',
    ANDROID_APP = 'ANDROID_APP',
    OTHER = 'OTHER'
}

export interface AgentInfo {
    counter: number;
    lastUpdated: string;
}

export interface DailyInfo {
    today: string;
    userAgents: string[];
}

export interface QLignModel {
    _id?: string;
    uuid: string;
    osVersion: string;
    alignerVersion: string;
    operatingHours: number;
    clientInfo: {
        [key: string]: AgentInfo;
    }
    dailyInfo: DailyInfo;
    nrReports: number;
    nrCustomers: number;
    versionTargetData: string;
    usbPrinterConnected: boolean;
    nrUsbPrintJobs: number;
    timestamp: string;
}

export interface UpdateQLignModel {
    uuid: string;
    osVersion?: string;
    alignerVersion?: string;
    timestamp?: string;
}

export interface AlignerVersion {
    alignerVersion: string;
    count: number;
}

export interface OsVersion {
    osVersion: string;
    count: number;
}

export interface UserDeviceDistribution {
    hdmi: number;
    desktop: number;
    android: number;
    other: number;
}

export interface UploadFile {
    name: string;
    nrBytes: number;
    published: boolean;
}
